/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import gql from 'graphql-tag';
import { GRAPHQL_ENV } from '..';
import { setLocale } from '../../../state/ducks/Member/ducks/Auth/helper/helper';

/**
 * @param {string} brand - baskets or berries
 * @param {object} page - contains path and contentType
 */

const determineProductOptions = (userSubmittedZip = '', locationType = 'Residence', isBot = false, pageSize = 12, brand) => {
    if (isBot) {
        // render all products for bots
        return `{locationType: "${locationType}"}`;
    }
    if (userSubmittedZip !== '' && !pageSize !== '') {
        return `{locationType: "${locationType}", zipCode: "${userSubmittedZip}", page: 1, pageSize: ${pageSize}}`;
    }
    if (userSubmittedZip !== '') {
        return `{locationType: "${locationType}", zipCode: "${userSubmittedZip}", page: 1, pageSize: 12}`;
    }
    return `{locationType: "${locationType}", page: 1, pageSize: ${(brand && brand === 'plants') ? pageSize : '12'}}`;
};

const findCategoryPageByUrl = (brand, url, contentType = 'standard_collections', userSubmittedZip, locationType, isBot, currency, pageSize) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql` { findCategoryPageByUrl(brand: "${brand}", environment: "${GRAPHQL_ENV}", locale: "${setLocale()}", currency: "${currency}" url: "${url}", contentType: "${contentType}", productOptions: ${determineProductOptions(userSubmittedZip, locationType, isBot, pageSize, brand)}) {
                    content
                    showUnderNavText
                    brand
                    category {
                        id
                        legacyId
                        name
                        guidedNavFlag
                         productFacets {
                                name
                                    entries{
                                        name
                                        value
                                        count
                                        from
                                        to
                                    }
                            }
                        breadCrumbs {
                            id
                            name
                            __typename
                        seo {
                            keywords
                            metaDescription
                            title
                            url
                            __typename
                            }
                        __typename
                    }
                    products {
                        id
                        image {
                            altText
                            name
                            path
                            snipe
                            snipeImagePath
                            __typename
                        }
                        partNumber
                        baseCode
                        prices {
                            currency
                            type
                            value
                            __typename
                        }
                        skuPriceRange {
                            sale {
                                value
                                __typename
                            }
                            retail {
                                value
                                __typename
                            }
                            __typename
                        }
                        name
                        brand
                        seo {
                            url
                            __typename
                        }
                        availability {
                            availabilityIndicator
                            deliveryDateType
                            displayEndDate
                            displayStartDate
                            earliestShipDate
                            latestShipDate
                            perishable
                            productDeliveryType
                            shipAlone
                            deliveryMessage
                            __typename
                        }
                        isPassportEligible
                        __typename
                    }
                    image
                    shortDescription
                    seo {
                        keywords
                        title
                        metaDescription
                        __typename
                    }
                    totalPages
                    totalProducts
                    __typename
                }
                guidedNavForm
                samedayCutoff
                __typename
            }
        }
    `);

export default findCategoryPageByUrl;
