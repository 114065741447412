/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as tagManagerActions } from '../../../../../../state/ducks/TagManager/ducks/TagManager';

const styles = () => ({
    plantsBtn: {
        backgroundColor: '#B9E529',
        flexBasis: 'auto',
        border: '0',
        height: '50px',
        width: '246px',
        letterSpacing: '1.56px',
        margin: '2%',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000',
        cursor: 'pointer',
        '@media (max-width: 1264px)': {
            width: '215px',
        },
    },
});

const PlantsButton = ({
    classes,
    pageType = '',
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleEventClick = () => {
        history.push('/c/all-plants');
        dispatch(tagManagerActions.trackEvent({
            eventName: 'feature_interaction', // Requird for GA4 feature_click
            eventCategory: pageType,
            eventAction: 'Click',
            eventLabel: pageType === 'Homepage' ? 'Shop By Collection - All Products' : 'View All Products',
        }));
    };
    return (
        <button
            type="button"
            className={classes.plantsBtn}
            onClick={handleEventClick}
        >
            VIEW ALL PRODUCTS
        </button>
    );
};

PlantsButton.propTypes = {
    classes: object.isRequired,
    pageType: string.isRequired,
};

export default withStyles(styles)(PlantsButton);
