/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useLazyQuery } from '@apollo/react-hooks';
import  { Link } from 'react-router-dom';
import { object, func } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import mbpLogger from 'mbp-logger';
// redux, helpers, hooks, queries
import { actions as tagManagerActions } from '../../../../../../../../state/ducks/TagManager';
import * as app from '../../../../../../../../state/ducks/App';
import findURL from '../../../../../../../gql/queries/findURL';
import findCategoryPageByURL from '../../../../../../../gql/queries/findCategoryPageByURL';
import { determineCollectionPrefixPath } from '../../../../../../../helpers/determineInternationalizationPath';

const brandSelectors = app.ducks.brand.selectors;

const styles = (theme) => ({
    root: {
        display: 'block',
        margin: '0 10px 0',
        width: '220px',
        height: 'auto',
        textDecoration: 'none',
        color: '#000',
        cursor: 'pointer',
    },
    plantsRoot: {
        display: 'block',
        margin: '1% 0',
        width: '200px',
        height: 'auto',
        textDecoration: 'none',
        color: '#000',
        cursor: 'pointer',
        maxWidth: '25%',
        flex: '0 0 25%',
    },
    title: {
        margin: '10px 0 0 0',
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
        },
        color: theme.palette.black,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        height: '50px',
    },
    plantsTitle: {
        margin: '1.5rem 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: '13px',
        },
        color: theme.palette.black,
        font: 'normal normal normal 16px/32px Hind, sans-serif',
        letterSpacing: '0.8px',
        '& img': {
            '@media (max-width: 1264px)': {
                display: 'none',
            },
        },
    },
    thumbnailContainer: {
        // minWidth: "30px",
        // maxWidth: "300px",
    },
    plantsThumbnailContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    thumbnail: {
        display: 'block',
        width: '100%',
        height: 'auto',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        transition: 'all 0.3s ease 0s',
    },
    plantsThumbnail: {
        display: 'block',
        width: '180px',
        height: '180px',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '100%',
        '@media (max-width: 1264px)': {
            width: '118px',
            height: '118px',
        },
    },
});

const DesktopHPSilosUI = ({
    classes,
    trackEvent,
    brand: {
        domain = '',
        code = '08F',
    } = {},
    siloData = {},
}) => {
    const url = siloData?.image?.url || '';
    const href = siloData?.link?.href || '';
    const title = siloData?.link?.title || '';
    const [scale, setScale] = useState(1);

    // Apollo prefetching from mouseOver TODO mobile
    const FIND_URL_QUERY = findURL(domain, href);
    const FIND_CATEGORY_PAGE_QUERY = findCategoryPageByURL(domain, href);

    const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
    const [loadCategory, { errorCategory }] = useLazyQuery(FIND_CATEGORY_PAGE_QUERY);

    if (errorURL || errorCategory) { mbpLogger.logError({ message: `Apollo prefetching error in: errorURL: ${errorURL} or errorCategory ${errorCategory}` }); }

    const handleMouseIn = () => {
        setScale(1.06);
        loadURL();
        loadCategory();
    };
    const languageFlag = code === '08F';
    const targetURL = (code === '08F' || code === 'CAN') ? determineCollectionPrefixPath(href, languageFlag) : href;
    return (
        <Link
            title={title}
            className={code === 'PLA' ? classes.plantsRoot : classes.root}
            to={targetURL}
            onMouseOver={() => handleMouseIn()}
            onFocus={() => handleMouseIn()}
            onMouseLeave={() => setScale(1)}
            onClick={() => trackEvent({
                eventName: 'feature_interaction',
                eventCategory: 'Homepage',
                eventAction: code === 'PLA' ? 'Click' : 'Everyday Occasion Guide - [Click]',
                eventLabel: code === 'PLA' ? `Shop By Collection - ${title}` : title,
            })}
        >
            <div className={code === 'PLA' ? classes.plantsThumbnailContainer : classes.thumbnailContainer}>
                <img
                    aria-hidden="true"
                    alt={title}
                    src={url}
                    className={code === 'PLA' ? classes.plantsThumbnail : classes.thumbnail}
                    style={{ transform: `scale(${scale})` }}
                />
            </div>
            <div
                className={code === 'PLA' ? classes.plantsTitle : classes.title}
            >
                <span>{title}</span>
                {
                    code === 'PLA'
                        ? (
                            <img
                                className="arrow"
                                src="https://images.contentstack.io/v3/assets/blt4454dc0093319d35/blt6a3c096527de1bca/6304f16c7048695fabde5b13/cta-icon-5f14b1599a3aa38d122226c58f6a6ea1.jpg"
                                alt="arrow-icon"
                                style={{
                                    height: '15px',
                                    paddingLeft: '3%',
                                    verticalAlight: 'middle',
                                }}
                            />
                        ) : null
                }
            </div>
        </Link>

    );
};

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});

DesktopHPSilosUI.propTypes = {
    classes: object.isRequired,
    siloData: object.isRequired,
    trackEvent: func.isRequired,
    brand: object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DesktopHPSilosUI);
