/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import { object } from 'prop-types';
import DesktopHPSilosUI from './DesktopHPSilosUI/DesktopHPSilosUI';

class GraphqlHPSilosUI extends PureComponent {
    render() {
        const { siloData } = this.props;
        return (
            <DesktopHPSilosUI siloData={siloData} />
        );
    }
}
GraphqlHPSilosUI.propTypes = {
    siloData: object.isRequired,
};

export default (GraphqlHPSilosUI);
