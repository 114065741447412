/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, shape, string } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import Media from 'react-media';
import GraphqlHPSilosUI from './Partials/GraphqlHPSilosUI';
import PlantsButton from '../Buttons/PlantsButton';

const styles = (theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1440px',
        // minWidth: '1000px',
        textAlign: 'center',
        color: 'black',
        fontWeight: '200',
        letterSpacing: '0.4px',
        fontSize: '14px',
        fontFamily: 'Roboto, Regular',
        marginBottom: '15px',
        clear: 'both',
    },
    plantsRoot: {
        paddingTop: '1%',
        minHeight: '476px',
        clear: 'both',
        textAlign: 'center',
        maxWidth: '1440px',
        margin: '0 auto 1.5rem',
        letterSpacing: '0.4px',
        fontFamily: 'Bebas Neue, sans-serif',
    },
    container: {
        display: 'flex',
        flexWrap: 'no-wrap',
        flex: '0.2',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    plantsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        paddingRight: '15%',
        paddingLeft: '15%',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        '@media (max-width: 1264px)': {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    title: {
        width: '100%',
        marginBottom: '10px',
        '& p,h2,h3,h4,h5,h6': {
            textAlign: 'center',
            fontWeight: 'normal',
            margin: '0',
            fontSize: '28px',
            lineHeight: '32px',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
    },
    plantsTitle: {
        width: '100%',
        marginBottom: '10px',
        textAlign: 'center',
        fontFamily: 'BebasNeue',
        fontSize: '30px',
        lineHeight: '32px',
        letterSpacing: '1.32px',
        fontWeight: 'normal',
        '& h3': {
            fontWeight: 'normal',
            fontSize: '30px',
            margin: '24px 0',
            '@media (max-width: 1264px)': {
                fontSize: '22px',
            },
        },
    },
    plantsBtn: {
        backgroundColor: '#B9E529',
        border: '0',
        height: '50px',
        width: '246px',
        letterSpacing: '1.4px',
        margin: '2%',
        fontWeight: '700',
        font: '16px',
    },
});

const GraphqlHPSilos = ({ classes, data, brand }) => (
    <Media query="(min-width: 768px)">
        <div
            className={brand?.code === 'PLA' ? classes.plantsRoot : classes.root}
            style={
                brand?.code !== 'PLA'
                    ? {
                        color: data?.title_font_color || '#000',
                    } : null
            }
        >
            <ReactMarkdown
                source={data?.title || null}
                skipHtml
                className={
                    brand?.code === 'PLA'
                        ? classes.plantsTitle : classes.title
                }
                style={{
                    fontWeight: 'norma',
                    fontSize: '30px',
                }}
            />
            <Fade in timeout={700}>
                <div
                    className={brand?.code === 'PLA' ? classes.plantsContainer : classes.container}
                >
                    {data?.silo_group?.map((silo) => (
                        <GraphqlHPSilosUI key={silo.link.title} siloData={silo} />
                    ))}
                </div>
            </Fade>
            {(brand?.code === 'PLA')
                ? (
                    <PlantsButton classes={classes} data={data} pageType="Homepage" />
                ) : null }
        </div>
    </Media>
);

GraphqlHPSilos.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    brand: shape({
        code: string,
    }).isRequired,
};

export default withStyles(styles)(GraphqlHPSilos);
